import { lazy } from "react";
import MainLayout from "src/components/MainLayout";
import DashboardLayout from "src/components/DashboardLayout";
import PrivateRoute from "src/routing/PrivateRoute";
import PublicRoute from "src/routing/PublicRoute";
import { Navigate } from "react-router-dom";
import MarkSearch from "../pages/app/TimberMarkSearch";
import CreateTimbermarkGroupPage from "../pages/app/CreateTimbermarkGroupPage";
import Account from "../pages/app/Account";
import DashboardHome from "../pages/app/DashboardHome";
import Harvest from "../pages/app/Harvest";
import Legal from "../pages/Legal";

const Home = lazy(() => import("src/pages/Home"));
const Login = lazy(() => import("src/pages/Login"));
const Register = lazy(() => import("src/pages/Register"));
const NotFound = lazy(() => import("src/pages/NotFound"));

const routes = [
    {
        path: "/",
        element: <MainLayout />,
        children: [
            {
                path: "home",
                element: (
                    <PublicRoute>
                        <Home />
                    </PublicRoute>
                ),
            },
            {
                path: "login",
                element: (
                    <PublicRoute>
                        <Login />
                    </PublicRoute>
                ),
            },
            {
                path: "register",
                element: <Register />,
            },
            {
                path: "/",
                element: (
                    <PublicRoute>
                        <Navigate to="/home" />
                    </PublicRoute>
                ),
            },
            {
                path: "legal/terms",
                element: <Legal tab={0} />,
            },
            {
                path: "legal/privacy",
                element: <Legal tab={1} />,
            },
            { path: "404", element: <NotFound /> },
            { path: "*", element: <Navigate to="/404" /> },
        ],
    },
    {
        path: "app",
        element: <DashboardLayout />,
        children: [
            {
                index: true, // This will match /app exactly
                element: (
                    <PrivateRoute>
                        <DashboardHome />
                    </PrivateRoute>
                ),
            },
            {
                path: "search",
                element: (
                    <PrivateRoute>
                        <MarkSearch />
                    </PrivateRoute>
                ),
            },
            {
                path: "groups",
                element: (
                    <PrivateRoute>
                        <CreateTimbermarkGroupPage />
                    </PrivateRoute>
                ),
            },
            {
                path: "harvest/:harvestId",
                element: (
                    <PrivateRoute>
                        <Harvest />
                    </PrivateRoute>
                ),
            },
            {
                path: "account",
                element: (
                    <PrivateRoute>
                        <Account />
                    </PrivateRoute>
                ),
            },
            { path: "*", element: <Navigate to="/404" /> },
        ],
    },
];

export default routes;
