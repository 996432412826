import * as React from "react";
import { useEffect, useState } from "react";
import distance from "@turf/distance";
import bbox from "@turf/bbox";
import ReactMapGL, {
    Source,
    Layer,
    ScaleControl,
    FullscreenControl,
    NavigationControl,
} from "react-map-gl";
import { GetGeojsonCenter } from "../../utils/api";
import "mapbox-gl/dist/mapbox-gl.css";
import { MAPBOX_API_KEY } from "src/Config";
import { IconButton, Skeleton } from "@material-ui/core";
import MyLocation from "@material-ui/icons/MyLocation";

// eslint-disable-next-line import/no-webpack-loader-syntax
// mapboxgl.workerClass =
//     // eslint-disable-next-line import/no-webpack-loader-syntax
//     require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const scaleControlStyle = {
    position: "absolute",
    bottom: 36,
    left: 0,
    padding: "10px",
};

const fullscreenControlStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "10px",
};

const navigationStyle = {
    position: "absolute",
    top: 36,
    left: 0,
    padding: "10px",
};

const geolocateStyle = {
    position: "absolute",
    top: 0,
    right: 0,
    margin: 10,
};

function Map({ geo, geoTextMapLayer }) {
    const [geoJsonFeatures, setGeoJsonFeatures] = useState([]);
    const [saleVieport, setSaleViewport] = useState({
        latitude: 52.1417,
        longitude: -122.1417,
        zoom: 8,
    });
    const [mapIsLoading, setMapIsLoading] = useState(true);
    const [viewport, setViewport] = React.useState({
        latitude: 52.1417,
        longitude: -122.1417,
        zoom: 8,
    });

    useEffect(() => {
        async function GetBlocks() {
            if (!geo) return;

            let center = GetGeojsonCenter(geo);

            let max = 0;

            let viewport = {
                latitude: center.geometry.coordinates[1],
                longitude: center.geometry.coordinates[0],
                zoom:
                    max === 0
                        ? 7
                        : max < 3
                        ? 12.5
                        : max < 4
                        ? 12
                        : max < 5
                        ? 11.5
                        : max < 7
                        ? 10.5
                        : 10,
            };
            setViewport(viewport);
            setSaleViewport(viewport);
            setGeoJsonFeatures(geo.features);
            setMapIsLoading(false);

            return;
        }
        GetBlocks();
    }, [geo]);

    function handleRecentre() {
        setViewport(saleVieport);
    }

    const geojson = {
        type: "FeatureCollection",
        features: [...geoJsonFeatures],
    };

    const layerStyle = {
        id: "line",
        type: "line",
        paint: {
            "line-color": "#FF0000",
            "line-width": 2.5,
        },
    };
    const pointStyle = {
        id: "point",
        type: "circle",
        paint: {
            "circle-radius": 10,
            "circle-color": "#007cbf",
        },
    };

    const saleTextMapLayer = {
        type: "symbol",
        id: "polygon-label",
        layout: geoTextMapLayer,
        paint: {
            "text-color": "white",
            "text-halo-color": "#000",
            "text-halo-width": 1,
        },
    };
    return (
        <ReactMapGL
            {...viewport}
            width="100%"
            height="600px"
            mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
            onViewportChange={(viewport) => setViewport(viewport)}
            mapboxApiAccessToken={MAPBOX_API_KEY}
        >
            <Source
                id="my-data"
                type="geojson"
                data={geojson}
                attribution="Contains information licensed under the Open Government Licence – British Columbia"
            >
                <Layer {...layerStyle} />
                <Layer {...saleTextMapLayer} />
            </Source>
            <div style={navigationStyle}>
                <NavigationControl />
            </div>

            <div style={fullscreenControlStyle}>
                <FullscreenControl />
            </div>

            <div style={scaleControlStyle}>
                <ScaleControl />
            </div>

            <div style={geolocateStyle}>
                <IconButton
                    color="white"
                    style={{
                        background: "white",
                        borderRadius: 9,
                        height: 35,
                        width: 35,
                    }}
                    onClick={handleRecentre}
                >
                    <MyLocation />
                </IconButton>
            </div>
        </ReactMapGL>
    );
}

export default Map;
