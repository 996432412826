export const createLabelsAndDataset = (
    data,
    parent,
    child,
    sortDescending = false
) => {
    const labels = Object.keys(data);

    // Collect and set datasets
    const childSet = new Set();
    Object.values(data).forEach((parentElement) => {
        let sortedChildren = Object.keys(parentElement).map((childElement) => ({
            [child]: childElement,
            total: parentElement[childElement] || 0,
        }));

        sortedChildren.sort((a, b) => b.total - a.total);

        sortedChildren.forEach((childElement) => {
            childSet.add(childElement[child]);
        });
    });

    const datasets = Array.from(childSet).map((childElement) => ({
        label: childElement,
        data: labels.map((label) => data[label][childElement] || 0),
        backgroundColor: getRandomColor(),
        stack: "Stack 0",
    }));

    // Sort parent elements by their total data
    if (sortDescending) {
        datasets.sort(
            (a, b) =>
                b.data.reduce((sum, val) => sum + val, 0) -
                a.data.reduce((sum, val) => sum + val, 0)
        );
    }

    return { labels, datasets };
};

function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const calculateTotalStumpage = (timberMarkStatus) => {
    return timberMarkStatus.reduce(
        (total, status) =>
            total +
            parseFloat(status?.rateInfo?.standrate || 0) *
                parseFloat(status?.cruise?.net || status?.cruise?.total || 0),
        0
    );
};

export const calculateTotalVolume = (timberMarkStatus) => {
    return timberMarkStatus.reduce(
        (total, status) =>
            total +
            parseFloat(status?.cruise?.net || status?.cruise?.total || 0),
        0
    );
};

export const calculateTotalPerM3Stumpage = (timberMarkStatus) => {
    const totalStumpage = calculateTotalStumpage(timberMarkStatus);
    const totalVolume = calculateTotalVolume(timberMarkStatus);

    return totalVolume ? totalStumpage / totalVolume : 0;
};
