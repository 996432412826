import { Link as RouterLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    Button,
    Typography,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import InputIcon from "@material-ui/icons/Input";
import LoginIcon from "@material-ui/icons/Login";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import Thumbnail from "./Thumbnail";
import { SignOutUserAsync } from "src/utils/auth";
import mainNavItems from "src/components/mainNavbar/mainNavItems";
import { auth } from "../Firebase";

const MainNavbar = ({ onMobileNavOpen, ...rest }) => {
    const navigate = useNavigate();

    return (
        <AppBar elevation={2} {...rest} color="secondary">
            <Toolbar>
                <Hidden lgDown>
                    <RouterLink to="/home">
                        <Thumbnail />
                    </RouterLink>

                    <Typography
                        variant="h6"
                        color="white"
                        sx={{ marginLeft: 2 }}
                    >
                        FibreFlow
                    </Typography>

                    <Box sx={{ flexGrow: 0.1 }} />

                    {mainNavItems.map((item) => (
                        <Button
                            key={item.title}
                            color="secondary"
                            disableElevation={true}
                            variant="contained"
                            onClick={() => navigate(item.href)}
                        >
                            {item.title}
                        </Button>
                    ))}
                    <Button
                        color="secondary"
                        disableElevation={true}
                        variant="contained"
                        onClick={() =>
                            window.open(
                                "https://us20.list-manage.com/contact-form?u=a2dab33d5fbdb1ee039959c06&form_id=95fa048738208a59932f03567e5b539d"
                            )
                        }
                    >
                        Contact Us
                    </Button>
                </Hidden>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onMobileNavOpen}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
                <Box sx={{ flexGrow: 2 }} />

                {auth.currentUser ? (
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            SignOutUserAsync()
                                .then(() =>
                                    navigate("/login", { replace: true })
                                )
                                .catch((error) => console.error(error));
                        }}
                        endIcon={<InputIcon />}
                    >
                        Sign Out
                    </Button>
                ) : (
                    <>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => navigate("/login")}
                            endIcon={<LoginIcon />}
                        >
                            Sign In
                        </Button>
                        <Box sx={{ flexGrow: 0.01 }} />
                        <Button
                            color="accent"
                            variant="contained"
                            onClick={() => navigate("/login")}
                            endIcon={<LockOpenIcon />}
                        >
                            Sign Up
                        </Button>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

MainNavbar.propTypes = {
    onMobileNavOpen: PropTypes.func,
};

export default MainNavbar;
