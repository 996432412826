import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
    Box,
    Divider,
    Drawer,
    Hidden,
    List,
    Typography,
} from "@material-ui/core";

import { CheckSquare as CheckIcon, User as UserIcon } from "react-feather";
import NavItem from "./NavItem";

import { getAuth } from "firebase/auth";
import { GetUser } from "src/utils/api";
import { Search, Workspaces } from "@material-ui/icons";

const items = [
    // {
    //     href: "/app/timberToday",
    //     icon: CheckIcon,
    //     title: "Timber Today",
    // },
    {
        href: "/app/search",
        icon: Search,
        title: "Search",
    },
    {
        href: "/app/groups",
        icon: Workspaces,
        title: "Groups",
    },
    {
        href: "/app/account",
        icon: UserIcon,
        title: "Account",
    },
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
    const location = useLocation();
    const [currentUser, setCurrentUser] = useState({});

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        async function SetUser() {
            let u = getAuth().currentUser?.uid;
            if (!u) return;
            const user = await GetUser(u);

            const listenerUnsubscribe = setCurrentUser(user);
            return () => {
                listenerUnsubscribe();
            };
        }

        SetUser();
    }, []);

    const content = (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
            }}
        >
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    p: 5,
                }}
            >
                {/* <Avatar
                    component={RouterLink}
                    src={currentUser.avatar ?}
                    sx={{
                        cursor: "pointer",
                        width: 64,
                        height: 64,
                    }}
                    to="/app/account"
                /> */}
                <Typography color="textPrimary" variant="h5">
                    {currentUser.name}
                </Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
                <List>
                    {items.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                        />
                    ))}
                </List>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                    PaperProps={{
                        sx: {
                            width: 256,
                        },
                    }}
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer
                    anchor="left"
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: 256,
                            top: 64,
                            height: "calc(100% - 80px)",
                        },
                    }}
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
    onMobileClose: () => {},
    openMobile: false,
};

export default DashboardSidebar;
