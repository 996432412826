import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "src/contexts/AuthContext";

const PrivateRoute = ({ children }) => {
    const { user, loading, profileCompleted } = useAuth();

    if (loading) {
        return <div>Loading...</div>; // Replace with your loading component
    }

    if (!user) {
        return <Navigate to="/login" />; // Redirect to login if not authenticated
    }

    if (!profileCompleted) {
        return <Navigate to="/register" />; // Redirect to profile completion if not completed
    }

    return children; // Render the private route component if authenticated and profile completed
};

export default PrivateRoute;
