import {
    NavLink as RouterLink,
    matchPath,
    useLocation,
} from "react-router-dom";
import PropTypes from "prop-types";
import { Button, ListItem } from "@material-ui/core";
import { SignOutUserAsync } from "src/utils/auth";
import { store } from "src/redux/Store";
import { TIMBER_SALE_LIST_PAGE_LIMIT } from "src/Config";
import { navigate } from "react-big-calendar/lib/utils/constants";

const NavItem = ({ href, icon: Icon, title, ...rest }) => {
    const location = useLocation();

    const active = href
        ? !!matchPath(
              {
                  path: href,
                  end: false,
              },
              location.pathname
          )
        : false;

    function onClickHandler(event) {
        if (event.target.innerText === "Sign Out") {
            SignOutUserAsync();
            navigate("/login", { replace: true });
        }
    }

    return (
        <ListItem
            disableGutters
            sx={{
                display: "flex",
                py: 0,
            }}
            {...rest}
        >
            <Button
                component={RouterLink}
                onClick={onClickHandler}
                sx={{
                    color: "text.secondary",
                    fontWeight: "medium",
                    justifyContent: "flex-start",
                    letterSpacing: 0,
                    py: 1.25,
                    textTransform: "none",
                    width: "100%",
                    ...(active && {
                        color: "primary.main",
                    }),
                    "& svg": {
                        mr: 1,
                    },
                }}
                to={href}
            >
                {Icon && <Icon size="20" />}

                <span>{title}</span>
            </Button>
        </ListItem>
    );
};

NavItem.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string,
};

export default NavItem;
