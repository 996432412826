import { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import GlobalStyles from "src/components/GlobalStyles";
import theme from "src/theme";
import routes from "src/routing/routes";
import Loading from "./pages/Loading";

const App = () => {
    const routing = useRoutes(routes);

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Suspense fallback={<Loading />}>{routing}</Suspense>
        </ThemeProvider>
    );
};

export default App;
