import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "src/contexts/AuthContext";

const PublicRoute = ({ children }) => {
    const { user, loading, profileCompleted } = useAuth();

    if (loading) {
        return <div>Loading...</div>; // Replace with your loading component
    }

    // If the user is authenticated and their profile is completed, redirect them to the app
    return user && profileCompleted ? <Navigate to="/app" /> : children;
};

export default PublicRoute;
