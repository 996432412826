import React, { createContext, useContext, useState, useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { USER_COLLECTION } from "src/Constants";
import { SignOutUserAsync } from "src/utils/auth";

const AuthContext = createContext();

const SIGNOUT_TIME_LIMIT = 7 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [profileCompleted, setProfileCompleted] = useState(false);
    const auth = getAuth();
    const db = getFirestore();

    // Function to check if the profile is completed
    const checkProfileCompletion = async (uid) => {
        const userDoc = await getDoc(doc(db, USER_COLLECTION, uid));
        setProfileCompleted(userDoc.exists()); // Profile is completed if userDoc exists
    };

    // Exposed function to manually refresh profile completion state
    const refreshProfileCompletion = async () => {
        if (user?.uid) {
            await checkProfileCompletion(user.uid); // Re-fetch the user profile from Firestore
        }
    };

    // Function to check last sign-in time and logout if it exceeds the threshold
    const checkLastSignInTime = (user) => {
        const lastSignInTime = new Date(user.metadata.lastSignInTime).getTime();
        const currentTime = Date.now();

        if (currentTime - lastSignInTime > SIGNOUT_TIME_LIMIT) {
            logout(); // Sign out if the time exceeds the limit
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                await checkProfileCompletion(user.uid); // Check profile on auth state change
                checkLastSignInTime(user); // Check the last sign-in time
                setUser(user);
            } else {
                setUser(null);
                setProfileCompleted(false); // Reset profile status if no user
            }
            setLoading(false); // Stop loading after the check
        });

        return () => unsubscribe(); // Cleanup listener on unmount
    }, [db]);

    const logout = async () => {
        await SignOutUserAsync();
        setUser(null);
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                loading,
                profileCompleted,
                refreshProfileCompletion, // Make sure this is provided
                logout,
            }}
        >
            {!loading && children}
        </AuthContext.Provider>
    );
};

// Custom hook to access the AuthContext
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
