import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Tooltip,
    IconButton,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Avatar,
    Chip,
    CircularProgress,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import WorkSpaceIcon from "@material-ui/icons/Workspaces";
import DeleteIcon from "@material-ui/icons/Delete"; // Import the Delete icon
import { useNavigate } from "react-router"; // Import useNavigate for navigation
import {
    CheckIfTimbermarkExistsInHBS,
    GetSaleStatusFromHBS,
} from "src/utils/hbs";
import {
    CreateTimbermarkGroup,
    GetGroupsForUser,
    GetInvoicesForTimbermarks,
    DeleteTimbermarkGroup, // Assuming you have a DeleteGroup function in your API
} from "src/utils/api"; // Assuming these functions exist
import LogitHelmet from "src/components/shared/LogitHelmet";

const CreateTimbermarkGroupPage = () => {
    const [open, setOpen] = useState(false);
    const [timbermarks, setTimbermarks] = useState([
        { value: "", valid: null },
    ]);
    const [error, setError] = useState("");
    const [groupName, setGroupName] = useState("");
    const [groups, setGroups] = useState([]); // State to hold existing groups
    const [newGroupCreated, setNewGroupCreated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false); // State to control delete confirmation modal
    const [groupToDelete, setGroupToDelete] = useState(null); // State to hold the group to be deleted

    const navigate = useNavigate(); // Initialize useNavigate

    const examples = [
        {
            name: "Strait of Georgia BCTS",
            timbermarks: ["A2023", "A0703", "A1092", "A1685"],
        },
        {
            name: "River City Fibre",
            timbermarks: ["A2314", "DZ5007", "ER7570"],
        },
        {
            name: "Gorman",
            timbermarks: ["56/206", "ER70R1", "K2U014"],
        },
        // { "name": "Mountain Timber", "timbermarks": ["A2314", "DZ5007", "ER7570"] },
        // { "name": "Valley Lumber", "timbermarks": ["A2314", "DZ5007", "ER7570"] },
    ];
    // Fetch existing groups when the component mounts
    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const userGroups = await GetGroupsForUser(); // Replace with your actual API call

                setGroups(userGroups);
            } catch (error) {
                setError("An error occurred while fetching the groups.");
            }
        };

        fetchGroups();
    }, [newGroupCreated]);

    const handleAddTimbermark = () => {
        if (timbermarks.length < 10) {
            setTimbermarks([...timbermarks, { value: "", valid: null }]);
        }
    };

    const handleDeleteTimbermark = (index) => {
        const newTimbermarks = timbermarks.filter((_, i) => i !== index);
        setTimbermarks(newTimbermarks);
    };

    const handleTimbermarkChange = (index, value) => {
        const newTimbermarks = [...timbermarks];
        newTimbermarks[index].value = value;
        newTimbermarks[index].valid = null; // Reset the validity until checked
        setTimbermarks(newTimbermarks);

        // Check if timbermark exists after user input
        if (value) {
            checkTimbermarkExists(index, value);
        }
    };

    const checkTimbermarkExists = async (index, value) => {
        try {
            if (value.length < 5) {
                return;
            }
            const exists = await CheckIfTimbermarkExistsInHBS(value); // Replace with your actual API call
            const newTimbermarks = [...timbermarks];
            newTimbermarks[index].valid = exists;
            setTimbermarks(newTimbermarks);
        } catch (error) {
            setError("An error occurred while checking the timbermark.");
        }
    };

    const handleCreateGroup = () => {
        const validTimbermarks = timbermarks
            .filter((t) => t.valid)
            .map((t) => t.value);

        if (validTimbermarks.length === timbermarks.length) {
            const finalGroupName =
                groupName.trim() || `Group ${new Date().toISOString()}`;

            CreateTimbermarkGroup({
                name: finalGroupName,
                timbermarks: validTimbermarks,
            })
                .then(() => {
                    setOpen(false);
                    setGroupName(""); // Reset group name field after successful creation
                    setNewGroupCreated(!newGroupCreated);
                })
                .catch((error) => {
                    setError("An error occurred while creating the group.");
                });
        } else {
            setError("Please correct the invalid timbermarks.");
        }
    };

    // Handle clicking on a group
    const handleGroupClick = async (group) => {
        try {
            const { name, timbermarks, slug } = group;
            setLoading(true); // Start loading
            // Call GroupSearch to collect invoices based on the timbermarks
            const invoices = await GetInvoicesForTimbermarks(timbermarks);
            // Get the statuses for all the timbermarks
            const timbermarkStatuses = await Promise.all(
                timbermarks.map((timbermark) =>
                    GetSaleStatusFromHBS(timbermark)
                )
            );
            navigate(`/app/harvest/${slug}`, {
                state: {
                    invoices: invoices,
                    timberMarkStatus: timbermarkStatuses,
                },
                replace: true,
            });
        } catch (error) {
            console.error(
                "An error occurred while searching for the group:",
                error
            );
            setError("An error occurred while searching for the group.");
        } finally {
            setLoading(false); // Stop loading
        }
    };

    // Function to handle delete button click, opens confirmation modal
    const handleOpenDeleteConfirm = (group) => {
        setGroupToDelete(group);
        setConfirmDeleteOpen(true);
    };

    // Function to close the delete confirmation modal
    const handleCloseDeleteConfirm = () => {
        setConfirmDeleteOpen(false);
        setGroupToDelete(null);
    };

    // Function to confirm and delete the group
    const handleConfirmDeleteGroup = async () => {
        try {
            await DeleteTimbermarkGroup(groupToDelete.id); // Replace with your actual API call
            setNewGroupCreated(!newGroupCreated); // Trigger re-fetch of groups
            handleCloseDeleteConfirm(); // Close the confirmation modal
        } catch (error) {
            setError("An error occurred while deleting the group.");
        }
    };

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    return (
        <>
            <LogitHelmet pageName={"Groups"} />

            <Container maxWidth="lg">
                <Box mt={4} display="flex" justifyContent="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenModal}
                        startIcon={<AddIcon />}
                    >
                        Create Group
                    </Button>
                </Box>

                <Box mt={4}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography variant="body2" ml={2}>
                                Create a group of timbermarks to view their
                                harvest billing details.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mb={4}>
                        {groups?.map((group) => (
                            <Grid item xs={12} md={6} key={group.id}>
                                <Card
                                    elevation={3}
                                    style={{
                                        cursor: "pointer",
                                        position: "relative",
                                    }} // Add position relative
                                >
                                    {loading && (
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            position="absolute"
                                            top={0}
                                            left={0}
                                            right={0}
                                            bottom={0}
                                            zIndex={1}
                                            bgcolor="rgba(255, 255, 255, 0.7)"
                                        >
                                            <CircularProgress />
                                        </Box>
                                    )}
                                    <CardHeader
                                        avatar={
                                            <Avatar>
                                                <WorkSpaceIcon />
                                            </Avatar>
                                        }
                                        title={group.name}
                                        titleTypographyProps={{ variant: "h6" }}
                                        subheader={`Timbermarks: ${group.timbermarks.length}`}
                                        action={
                                            <IconButton
                                                aria-label="delete"
                                                onClick={(event) => {
                                                    event.stopPropagation(); // Prevents the CardHeader onClick from firing
                                                    handleOpenDeleteConfirm(
                                                        group
                                                    );
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                        onClick={() => handleGroupClick(group)}
                                    />
                                    <CardContent>
                                        <Box
                                            display="flex"
                                            flexWrap="wrap"
                                            gap={1}
                                        >
                                            {group.timbermarks.map(
                                                (timbermark, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={timbermark}
                                                        color="primary"
                                                        variant="outlined"
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Typography variant="body2" ml={2}>
                        Not sure where to start? Check out these example groups
                    </Typography>
                    <Grid container spacing={2}>
                        {examples?.map((group) => (
                            <Grid item xs={12} md={6} key={group.id}>
                                <Card
                                    elevation={3}
                                    style={{
                                        cursor: "pointer",
                                        position: "relative",
                                    }} // Add position relative
                                >
                                    {loading && (
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            position="absolute"
                                            top={0}
                                            left={0}
                                            right={0}
                                            bottom={0}
                                            zIndex={1}
                                            bgcolor="rgba(255, 255, 255, 0.7)"
                                        >
                                            <CircularProgress />
                                        </Box>
                                    )}
                                    <CardHeader
                                        avatar={
                                            <Avatar>
                                                <WorkSpaceIcon />
                                            </Avatar>
                                        }
                                        title={group.name}
                                        titleTypographyProps={{ variant: "h6" }}
                                        subheader={`Timbermarks: ${group.timbermarks.length}`}
                                        action={
                                            <IconButton
                                                aria-label="delete"
                                                onClick={(event) => {
                                                    event.stopPropagation(); // Prevents the CardHeader onClick from firing
                                                    handleOpenDeleteConfirm(
                                                        group
                                                    );
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                        onClick={() => handleGroupClick(group)}
                                    />
                                    <CardContent>
                                        <Box
                                            display="flex"
                                            flexWrap="wrap"
                                            gap={1}
                                        >
                                            {group.timbermarks.map(
                                                (timbermark, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={timbermark}
                                                        color="primary"
                                                        variant="outlined"
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                <Dialog
                    open={open}
                    onClose={handleCloseModal}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>Create a Timbermark Group</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            label="Group Name (Optional)"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                        />
                        <Typography variant="body2" mt={2}>
                            Enter up to 10 Timbermarks.
                        </Typography>
                        {timbermarks.map((timbermark, index) => (
                            <Box
                                key={index}
                                display="flex"
                                alignItems="center"
                                mb={1}
                            >
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    label={`Timbermark ${index + 1}`}
                                    value={timbermark.value}
                                    onChange={(e) =>
                                        handleTimbermarkChange(
                                            index,
                                            e.target.value
                                        )
                                    }
                                    error={timbermark.valid === false}
                                    helperText={
                                        timbermark.valid === false &&
                                        "Invalid Timbermark"
                                    }
                                />
                                {timbermark.valid === true && (
                                    <CheckCircleIcon
                                        style={{
                                            color: "green",
                                            marginLeft: 8,
                                        }}
                                    />
                                )}
                                {timbermark.valid === false && (
                                    <Tooltip
                                        title="This timbermark doesn't appear to exist"
                                        arrow
                                    >
                                        <CancelIcon
                                            style={{
                                                color: "red",
                                                marginLeft: 8,
                                            }}
                                        />
                                    </Tooltip>
                                )}
                                <IconButton
                                    aria-label="delete"
                                    onClick={() =>
                                        handleDeleteTimbermark(index)
                                    }
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ))}
                        {timbermarks.length < 10 && (
                            <Box mt={2} display="flex" justifyContent="center">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleAddTimbermark}
                                >
                                    Add Another Timbermark
                                </Button>
                            </Box>
                        )}
                        {error && (
                            <Typography color="error" variant="body2">
                                {error}
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleCreateGroup}
                            color="primary"
                            variant="contained"
                        >
                            Create Group
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Delete Confirmation Dialog */}
                <Dialog
                    open={confirmDeleteOpen}
                    onClose={handleCloseDeleteConfirm}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Are you sure you want to delete the group '
                            {groupToDelete?.name}'?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseDeleteConfirm}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleConfirmDeleteGroup}
                            color="secondary"
                            variant="contained"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
};

CreateTimbermarkGroupPage.propTypes = {
    onMobileNavOpen: PropTypes.func,
};

export default CreateTimbermarkGroupPage;
