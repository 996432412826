import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    Typography,
    Button,
    useMediaQuery,
    Tooltip,
} from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const localizer = momentLocalizer(moment);

const LoadCalendar = ({ invoices }) => {
    const [loads, setLoads] = useState([]);
    const [events, setEvents] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [firstLoadDate, setFirstLoadDate] = useState(null);
    const [lastLoadDate, setLastLoadDate] = useState(null);
    const [summary, setSummary] = useState({ uniqueDates: 0, avgVolume: 0 });
    const [uniqueTimberMarks, setUniqueTimberMarks] = useState([]);
    const [selectedTimberMarks, setSelectedTimberMarks] = useState([]);

    // Media query to detect if the screen is small (for mobile)
    const isMobile = useMediaQuery("(max-width: 600px)");

    useEffect(() => {
        if (invoices) {
            let loads = [];
            let timberMarksSet = new Set();

            // Combine loads from both wsi and psi invoices
            const processInvoices = (invoiceList) => {
                invoiceList?.forEach((invoice) => {
                    if (invoice.header["kind-of-charge"] === "Waste") {
                        return;
                    }

                    if (invoice["detail-documents"]) {
                        let detailDocuments = Array.isArray(
                            invoice["detail-documents"]["detail-document"]
                        )
                            ? invoice["detail-documents"]["detail-document"]
                            : [invoice["detail-documents"]["detail-document"]];

                        detailDocuments.forEach((doc) => {
                            doc = {
                                ...doc,
                                scaleSiteName:
                                    invoice.header["scale-site"]["name"],
                                timberMark: invoice.header["timber-mark"],
                            };

                            loads.push(doc);
                            timberMarksSet.add(doc.timberMark);
                        });
                    }
                });
            };

            processInvoices(invoices?.wsi);
            processInvoices(invoices?.psi);

            setLoads(loads);

            const eventMap = {};

            // Group loads by scale date and sum up their volumes
            loads.forEach((load) => {
                const scaleDate = moment(load["scale-date"])
                    .startOf("day")
                    .format("YYYY-MM-DD");
                const volume =
                    parseFloat(load["additional-volume"]) ||
                    parseFloat(load["volume"]) ||
                    0;
                if (!eventMap[scaleDate]) {
                    eventMap[scaleDate] = {
                        count: 0,
                        volume: 0,
                        timberMark: load.timberMark,
                    };
                }
                eventMap[scaleDate].count += 1; // Count loads on the same day
                eventMap[scaleDate].volume += volume; // Sum volume on the same day
            });

            const calendarEvents = Object.keys(eventMap).map((date) => ({
                title: `${eventMap[date].count} load(s)`,
                loads: eventMap[date].count,
                start: moment(date).toDate(),
                end: moment(date).toDate(),
                volume: eventMap[date].volume,
                timberMark: eventMap[date].timberMark, // Add timberMark to the event
            }));

            setEvents(calendarEvents);

            // Get the load dates and sort them chronologically
            const loadDates = Object.keys(eventMap).sort((a, b) =>
                moment(a).diff(moment(b))
            );

            if (loadDates.length > 0) {
                setFirstLoadDate(moment(loadDates[0]).toDate());
                setLastLoadDate(
                    moment(loadDates[loadDates.length - 1]).toDate()
                );
                setCurrentDate(
                    moment(loadDates[loadDates.length - 1]).toDate()
                );
            }

            // Calculate summary
            const uniqueDates = loadDates.length;
            const totalVolume = calendarEvents.reduce(
                (acc, event) => acc + event.volume,
                0
            );
            const avgVolume = uniqueDates ? totalVolume / uniqueDates : 0;

            setSummary({ uniqueDates, avgVolume });
            setUniqueTimberMarks([...timberMarksSet]);
            setSelectedTimberMarks([...timberMarksSet]); // Select all by default
        }
    }, [invoices]);

    const handleToggle = (event, newValues) => {
        setSelectedTimberMarks(newValues);
    };

    const goToFirstLoad = () => {
        if (firstLoadDate) {
            setCurrentDate(firstLoadDate);
        }
    };

    const goToLastLoad = () => {
        if (lastLoadDate) {
            setCurrentDate(lastLoadDate);
        }
    };

    const filteredEvents = events.filter((event) =>
        selectedTimberMarks.includes(event.timberMark)
    );

    return (
        <Box sx={{ padding: 2 }}>
            {/* Toggle Button Group for Timbermarks */}
            <ToggleButtonGroup
                value={selectedTimberMarks}
                onChange={handleToggle}
                aria-label="timbermark toggle"
            >
                {uniqueTimberMarks.map((mark) => (
                    // add tooltip to say whether to turn on or off
                    <Tooltip title="Click to toggle">
                        <ToggleButton key={mark} value={mark}>
                            {mark}
                        </ToggleButton>
                    </Tooltip>
                ))}
            </ToggleButtonGroup>

            <Card sx={{ borderRadius: 3, boxShadow: 3, mb: 4 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Loads Calendar
                    </Typography>
                    <Box display="flex" justifyContent="space-between" mb={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={goToFirstLoad}
                            sx={{
                                borderRadius: 2,
                                minWidth: isMobile ? "45%" : "auto",
                                padding: isMobile ? "6px" : "12px",
                            }}
                        >
                            First Load
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={goToLastLoad}
                            sx={{
                                borderRadius: 2,
                                minWidth: isMobile ? "45%" : "auto",
                                padding: isMobile ? "6px" : "12px",
                            }}
                        >
                            Last Load
                        </Button>
                    </Box>
                    <Box>
                        <Calendar
                            localizer={localizer}
                            events={filteredEvents} // Apply filtered events
                            startAccessor="start"
                            endAccessor="end"
                            style={{
                                height: isMobile ? 300 : 500,
                                borderRadius: 3,
                                boxShadow: 2,
                            }}
                            views={isMobile ? ["month"] : ["month", "week"]}
                            components={{
                                event: ({ event }) => (
                                    <Box
                                        sx={{
                                            borderRadius: 2,
                                            backgroundColor: "secondary.main",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "2px",
                                            fontSize: isMobile
                                                ? "0.7rem"
                                                : "0.9rem",
                                            color: "white",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {event.title}
                                        </Typography>
                                        <Typography variant="body2">
                                            {event.volume.toFixed(0)} m³
                                        </Typography>
                                    </Box>
                                ),
                            }}
                            date={currentDate}
                            onNavigate={(date) => setCurrentDate(date)}
                        />
                    </Box>
                </CardContent>
            </Card>

            {/* Summary Card */}
            <Card
                sx={{
                    borderRadius: 3,
                    boxShadow: 3,
                    display: isMobile ? "none" : "block",
                }}
            >
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Summary
                    </Typography>
                    <Typography variant="body1">
                        Unique Shipping Dates: {summary.uniqueDates}
                    </Typography>
                    <Typography variant="body1">
                        Average Volume per Date: {summary.avgVolume?.toFixed(2)}{" "}
                        m³
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
};

export default LoadCalendar;
