import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import "./pageTransition.css"; // Custom CSS for transitions

const PageTransition = ({ children }) => {
    const location = useLocation(); // Get the current route location

    return (
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                timeout={300} // Transition duration in milliseconds
                classNames="fade"
            >
                <Box
                    sx={{
                        // position: "absolute",
                        width: "100%",
                        top: 0,
                        left: 0,
                    }}
                >
                    {children}
                </Box>
            </CSSTransition>
        </TransitionGroup>
    );
};

export default PageTransition;
