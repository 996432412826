function GetFileNameFromUrl(url) {
    var splits = url.split("/");
    return splits[splits.length - 1];
}

function isMobile() {
    return window.innerWidth <= 768;
}

function formatCurrency(value) {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "CAD",
        currencyDisplay: "narrowSymbol",
    });

    return formatter.format(value);
}

function formatNumber(value) {
    return value.toLocaleString("en-US");
}

function formatPercentage(value) {
    let percentage = value * 100; // Convert the float to a percentage
    let formatter = new Intl.NumberFormat("en-US", {
        style: "percent",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    let formattedPercentage = formatter.format(percentage / 100); // Convert the percentage back to a float
    return formattedPercentage;
}

function getAppBarTopPadding() {
    var dashboardNav = document.getElementById("dashNav");

    if (dashboardNav) {
        var rect = dashboardNav.getBoundingClientRect();

        if (rect) {
            return rect.bottom;
        }
    }
    return null;
}

function formatDateForHBS(dateIn) {
    let year = dateIn.getFullYear() * 1e4;
    let month = (dateIn.getMonth() + 1) * 100;
    let day = dateIn.getDate(); // 20211100 => 20211124
    const f = year + month + day + "";
    return f;
}

function formatTimbermarkForHbs(timbermark) {
    if (timbermark.includes("TA")) {
        return timbermark.replace("T", "");
    } else if (timbermark.includes("A")) {
        return timbermark.replace("A", "");
    } else {
        return timbermark;
    }
}

const cleanText = (text) => {
    return text.replace(/[^\x00-\x7F]/g, ""); // Removes non-ASCII characters
};

export {
    GetFileNameFromUrl,
    isMobile,
    formatNumber,
    formatCurrency,
    formatPercentage,
    getAppBarTopPadding,
    formatDateForHBS,
    formatTimbermarkForHbs,
    cleanText,
};
