import React, { useEffect } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    TableCell,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography,
    TablePagination,
} from "@material-ui/core";
import ScrollBar from "react-perfect-scrollbar";
import { formatCurrency } from "src/utils/helpers";
import * as XLSX from "xlsx";

const WeightScaledLoads = ({ invoices }) => {
    const [loads, setLoads] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        if (invoices) {
            let loads = [];
            invoices?.wsi?.forEach((invoice) => {
                if (invoice["detail-documents"]) {
                    let detailDocuments = Array.isArray(
                        invoice["detail-documents"]["detail-document"]
                    )
                        ? invoice["detail-documents"]["detail-document"]
                        : [invoice["detail-documents"]["detail-document"]];
                    detailDocuments.forEach((doc) => {
                        doc = {
                            ...doc,
                            "scale-site-name":
                                invoice.header["scale-site"]["id"],
                            stratum: invoice.header["stratum-number"],
                            conversion: (
                                parseFloat(doc["net-weight"]) /
                                parseFloat(doc["additional-volume"])
                            ).toFixed(2),
                            ratio: (
                                1000 /
                                (parseFloat(doc["net-weight"]) /
                                    parseFloat(doc["additional-volume"]))
                            ).toFixed(2),
                            population: invoice.header["population-number"],
                            samplingYear: invoice.header["sampling-year"],
                            license: invoice.header["license"],
                            timberMark: invoice.header["timber-mark"],
                        };

                        loads.push(doc);
                    });
                }
            });
            setLoads(loads);
        }
    }, [invoices]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(loads);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "WeightScaledLoads");
        XLSX.writeFile(workbook, "WeightScaledLoads.xlsx");
    };

    return (
        <Card>
            <CardContent>
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Weight Scaled Loads
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={exportToExcel}
                        style={{ marginBottom: "10px" }}
                    >
                        Export to Excel
                    </Button>
                    <ScrollBar
                        style={{
                            maxHeight: "100%",
                            overflowY: "scroll",
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Load Count</TableCell>
                                    <TableCell>Document Number</TableCell>
                                    <TableCell>Load Slip Number</TableCell>
                                    <TableCell>Weigh Slip Number</TableCell>
                                    <TableCell>Scale Date</TableCell>
                                    <TableCell>Scaler</TableCell>
                                    <TableCell>Volume</TableCell>
                                    <TableCell>Weight</TableCell>
                                    <TableCell>Conversion/Ratio</TableCell>
                                    <TableCell>Stumpage</TableCell>
                                    <TableCell>Doc Version</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loads
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((doc, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {page * rowsPerPage +
                                                        index +
                                                        1}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => {
                                                            window.open(
                                                                `https://a100.gov.bc.ca/pub/hbs/dac/detail/searchSingle.do?searchType=1&txtDCN=${doc["DDN"]}&scaleSiteNumber=&weighSlipNumber=&scalerLicence=&returnNumber=&actionType=Submit&pageName=P048`
                                                            );
                                                        }}
                                                    >
                                                        {doc["DDN"]}
                                                    </Button>
                                                </TableCell>
                                                <TableCell>
                                                    {doc["LDS-number"]}
                                                </TableCell>
                                                <TableCell>
                                                    {doc["weigh-slip-number"]}
                                                </TableCell>
                                                <TableCell>
                                                    {doc["scale-date"]}
                                                </TableCell>
                                                <TableCell>
                                                    {doc["scaler-license"]}
                                                </TableCell>
                                                <TableCell>
                                                    {doc["additional-volume"]}
                                                </TableCell>
                                                <TableCell>
                                                    {doc["net-weight"]}
                                                </TableCell>
                                                <TableCell>
                                                    {doc.conversion}/{doc.ratio}
                                                </TableCell>
                                                <TableCell>
                                                    {formatCurrency(
                                                        doc["additional-value"]
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {doc["DV"]}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={loads.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </ScrollBar>
                </Box>
            </CardContent>
        </Card>
    );
};

export default WeightScaledLoads;
