import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Box, Tooltip } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
} from "@mui/material";
import { formatCurrency, formatNumber } from "src/utils/helpers";

const InvoiceStatement = ({ invoices }) => {
    const [headers, setHeaders] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [summedData, setSummedData] = useState({});

    useEffect(() => {
        if (invoices && invoices.psi) {
            let headers = [];
            invoices.psi.forEach((invoice) => {
                let header = invoice.header;
                headers.push(header);
            });
            invoices.wsi.forEach((invoice) => {
                let header = invoice.header;
                headers.push(header);
            });

            headers.sort((a, b) => {
                return (
                    new Date(b["scale-period"]["end"]) -
                    new Date(a["scale-period"]["end"])
                );
            });
            setHeaders(headers);

            // Summing volume and value by statement date
            const sums = headers.reduce((acc, header) => {
                const date = header["issue-date"];
                if (!acc[date]) {
                    acc[date] = { totalVolume: 0, totalValue: 0 };
                }
                acc[date].totalVolume += parseFloat(header["total-volume"]);
                acc[date].totalValue += parseFloat(header["invoice-amount"]);
                return acc;
            }, {});

            setSummedData(sums);
        }
    }, [invoices]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Card>
            <CardContent>
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Invoice Statements
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Statement</TableCell>
                                    <TableCell>Summary</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Scale Site</TableCell>
                                    <TableCell>Stratum</TableCell>
                                    <TableCell>Issue Date</TableCell>
                                    <TableCell>Scale Period Start</TableCell>
                                    <TableCell>Scale Period End</TableCell>
                                    <TableCell>Volume</TableCell>
                                    <TableCell>Conversion</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell>$/m³</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {headers
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((header, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Tooltip title="View Statement on HBS">
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => {
                                                            if (
                                                                header[
                                                                    "scale-method"
                                                                ] === "P"
                                                            ) {
                                                                window.open(
                                                                    `https://a100.gov.bc.ca/pub/hbs/StatementDelivery/statements/PSI/${header["statement-number"]}.pdf`
                                                                );
                                                            } else if (
                                                                header[
                                                                    "scale-method"
                                                                ] === "W"
                                                            ) {
                                                                window.open(
                                                                    `https://a100.gov.bc.ca/pub/hbs/StatementDelivery/statements/WSI/${header["statement-number"]}.pdf`
                                                                );
                                                            } else {
                                                            }
                                                        }}
                                                    >
                                                        <OpenInNew fontSize="small" />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="View Summary on HBS">
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => {
                                                            window.open(
                                                                `https://a100.gov.bc.ca/pub/hbs/dac/summary/singleSummaryScaleReturnSearch.do?txtDCN=${header["summary-doc-number"]}`
                                                            );
                                                        }}
                                                    >
                                                        <OpenInNew fontSize="small" />
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                {header["kind-of-charge"][0]}
                                            </TableCell>
                                            <TableCell>
                                                {header["scale-site"]
                                                    ? header["scale-site"][
                                                          "name"
                                                      ] ?? "N/A"
                                                    : "N/A"}
                                            </TableCell>
                                            <TableCell>
                                                {header["stratum-number"]}
                                            </TableCell>
                                            <TableCell>
                                                {header["issue-date"]}
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    header["scale-period"][
                                                        "start"
                                                    ]
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {header["scale-period"]["end"]}
                                            </TableCell>
                                            <TableCell>
                                                {formatNumber(
                                                    header["total-volume"]
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {(
                                                    header["total-weight"] /
                                                    header["total-volume"]
                                                ).toFixed(3)}
                                            </TableCell>
                                            <TableCell>
                                                {formatCurrency(
                                                    header["invoice-amount"]
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {formatCurrency(
                                                    header["invoice-amount"] /
                                                        header["total-volume"]
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 100]}
                            component="div"
                            count={headers.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                    <Box mt={2}>
                        <Typography variant="h6">
                            Totals by Statement Date
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Statement Date</TableCell>
                                        <TableCell>Total Volume</TableCell>
                                        <TableCell>Total Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(summedData).map(
                                        (date, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{date}</TableCell>
                                                <TableCell>
                                                    {formatNumber(
                                                        summedData[date]
                                                            .totalVolume
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {formatCurrency(
                                                        summedData[date]
                                                            .totalValue
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default InvoiceStatement;
