export const ENV = process.env.REACT_APP_ENV;
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY;
export const BCDATA_API_ENDPOINT =
    "https://us-west1-logitnow.cloudfunctions.net/logit-bcdata-api";

export const BCDATA_API_ENDPOINT_V2 =
    "https://us-west1-logitnow.cloudfunctions.net/https-logit-bcdata/v1/api";

export const CUSTOMER_SERVICE_EMAIL = "brad@logitnow.ca";
export const FREE_TRIAL_DAYS = 14;
