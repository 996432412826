import React from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    CardActions,
} from "@material-ui/core";
import LogitHelmet from "src/components/shared/LogitHelmet";

const DashboardHome = () => {
    const navigate = useNavigate();

    const handleSearchClick = () => {
        navigate("/app/search");
    };
    const handleGroupClick = () => {
        navigate("/app/groups");
    };

    return (
        <>
            <LogitHelmet pageName={"Home"} />
            <Box sx={{ padding: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Welcome to FibreFlow
                </Typography>
                <Typography variant="body1" paragraph>
                    FibreFlow helps you retrieve invoices issued by the Harvest
                    Billing System and display relevant summaries of harvest
                    details.
                </Typography>

                <Grid container spacing={4} sx={{ marginTop: 3 }}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Search for Timber Marks
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    The search functionality allows you to look
                                    up specific timber marks. You can retrieve
                                    detailed information about each mark,
                                    including invoices and summaries related to
                                    the harvest.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSearchClick}
                                >
                                    Go to Search
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Group Timber Marks
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    The groups feature enables you to group
                                    together multiple timber marks. By creating
                                    a group, you can view summaries of all
                                    included timber marks together, providing a
                                    comprehensive view of the associated harvest
                                    data.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleGroupClick}
                                >
                                    Go to Groups
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default DashboardHome;
