import React, { useEffect, useState } from "react";

import { Card, CardContent, Typography, Box } from "@material-ui/core";

import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import { Tab } from "@material-ui/icons";
import { formatCurrency, formatNumber } from "src/utils/helpers";
import { M3 } from "src/Constants";

const DestinationSummary = ({ timberMarkStatus, invoices }) => {
    const [destinationSummary, setDestinationSummary] = useState([]);

    useEffect(() => {
        // for each psi and wsi, add the header to headers
        let scaleSites = {};
        invoices?.wsi?.forEach((invoice) => {
            let scaleSite = invoice.header["scale-site"];

            scaleSites[scaleSite.id] = scaleSites[scaleSite.id] ?? [];
            scaleSites[scaleSite.id].push({
                totalVolume: invoice.header["total-volume"],
                totalValue: invoice.header["invoice-amount"],
                totalWeight: invoice.header["total-weight"],
                totalLoads: invoice.header["total-loads"],
                totalPieces: 0,
                scaleSiteName: scaleSite.name,
                scaleSiteId: scaleSite.id,
            });
        });

        invoices?.psi?.forEach((invoice) => {
            let type = invoice.header["kind-of-charge"];
            if (type === "Waste") {
                return;
            }

            let scaleSite = invoice.header["scale-site"];

            scaleSites[scaleSite.id] = scaleSites[scaleSite.id] ?? [];
            scaleSites[scaleSite.id].push({
                totalVolume: invoice.header["total-volume"],
                totalValue: invoice.header["invoice-amount"],
                totalWeight: invoice.header["total-weight"],
                totalLoads: invoice["detail-documents"]
                    ? Array.isArray(
                          invoice["detail-documents"]?.["detail-document"]
                      )
                        ? invoice["detail-documents"]?.["detail-document"]
                              ?.length ?? 0
                        : 1
                    : 0,
                totalPieces:
                    invoice["detail-documents"]?.["detail-totals"]?.[
                        "log-count"
                    ] ?? 0,
                scaleSiteName: scaleSite.name,
                scaleSiteId: scaleSite.id,
            });
        });

        //aggregate each stratum
        let aggregatedScaleSite = [];
        for (const site in scaleSites) {
            let totalVolume = 0;
            let totalValue = 0;
            let totalWeight = 0;
            let totalLoads = 0;
            let totalPieces = 0;
            let m3PerLoad = 0;
            scaleSites[site].forEach((invoice) => {
                totalVolume += parseFloat(invoice.totalVolume);
                totalValue += parseFloat(invoice.totalValue);
                totalWeight += parseFloat(invoice.totalWeight);
                totalLoads += parseFloat(invoice.totalLoads);
                totalPieces += parseFloat(invoice.totalPieces);
                m3PerLoad = parseFloat(totalVolume) / parseFloat(totalLoads);
            });

            aggregatedScaleSite.push({
                scaleSiteId: scaleSites[site][0]["scaleSiteId"],
                scaleSite: scaleSites[site][0]["scaleSiteName"],
                totalVolume: totalVolume,
                totalValue: totalValue,
                totalWeight: totalWeight,
                totalLoads: totalLoads,
                totalPieces: totalPieces,
                conversion: totalWeight / totalVolume,
                perMStumapge: totalValue / totalVolume,
                m3PerLoad: m3PerLoad,
            });

            // sort by volume
            aggregatedScaleSite.sort((a, b) => {
                return b["totalVolume"] - a["totalVolume"];
            });
        }

        setDestinationSummary(aggregatedScaleSite);
    }, [invoices]);

    return (
        <Card>
            <CardContent>
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Destination Summary
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Site</TableCell>
                                    <TableCell>Volume</TableCell>
                                    <TableCell>Loads</TableCell>
                                    <TableCell>Volume/Load</TableCell>
                                    <TableCell>Pieces</TableCell>
                                    <TableCell>Weight</TableCell>
                                    <TableCell>Conversion</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell>${M3}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {destinationSummary.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {}}
                                            >
                                                {row["scaleSite"]}
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            {formatNumber(row["totalVolume"])}
                                        </TableCell>
                                        <TableCell>
                                            {formatNumber(row["totalLoads"])}
                                        </TableCell>
                                        <TableCell>
                                            {formatNumber(row["m3PerLoad"])}
                                        </TableCell>
                                        <TableCell>
                                            {row["totalPieces"] === 0
                                                ? "-"
                                                : formatNumber(
                                                      row["totalPieces"]
                                                  )}
                                        </TableCell>
                                        <TableCell>
                                            {formatNumber(row["totalWeight"])}
                                        </TableCell>
                                        <TableCell>
                                            {formatNumber(row["conversion"])}
                                        </TableCell>
                                        <TableCell>
                                            {formatCurrency(row["totalValue"])}
                                        </TableCell>
                                        <TableCell>
                                            {formatCurrency(
                                                row["perMStumapge"]
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </CardContent>
        </Card>
    );
};

export default DestinationSummary;
