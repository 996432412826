import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    TableCell,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography,
    TablePagination,
} from "@material-ui/core";
import ScrollBar from "react-perfect-scrollbar";
import { formatCurrency } from "src/utils/helpers";
import * as XLSX from "xlsx";

const PieceScaledLoads = ({ invoices }) => {
    const [loads, setLoads] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        if (invoices) {
            let loads = [];
            invoices?.psi?.forEach((invoice) => {
                if (invoice.header["kind-of-charge"] === "Waste") {
                    return;
                }

                if (invoice["detail-documents"]) {
                    let detailDocuments = Array.isArray(
                        invoice["detail-documents"]["detail-document"]
                    )
                        ? invoice["detail-documents"]["detail-document"]
                        : [invoice["detail-documents"]["detail-document"]];
                    detailDocuments.forEach((doc) => {
                        doc = {
                            ...doc,
                            scaleSiteName: invoice.header["scale-site"]["name"],
                        };

                        loads.push(doc);
                    });
                }
            });
            setLoads(loads);

            return;
        }
        setLoads([]);
    }, [invoices]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(loads);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "PieceScaledLoads");
        XLSX.writeFile(workbook, "PieceScaledLoads.xlsx");
    };

    return (
        <div>
            <Card>
                <CardContent>
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Pieces Scaled Loads
                        </Typography>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={exportToExcel}
                            style={{ marginBottom: "10px" }}
                        >
                            Export to Excel
                        </Button>

                        <ScrollBar
                            style={{
                                maxHeight: "100%",
                                overflowY: "scroll",
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Load Count</TableCell>
                                        <TableCell>Document Number</TableCell>
                                        <TableCell>Load Slip Number</TableCell>
                                        <TableCell>
                                            Load Arrival Number
                                        </TableCell>
                                        <TableCell>Destination</TableCell>
                                        <TableCell>Scale Date</TableCell>
                                        <TableCell>Scaler</TableCell>
                                        <TableCell>Volume</TableCell>
                                        <TableCell>Log Count</TableCell>
                                        <TableCell>Volume per Log</TableCell>
                                        <TableCell>Stumpage</TableCell>
                                        <TableCell>Doc Version</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loads
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((doc, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {page * rowsPerPage +
                                                        index +
                                                        1}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => {
                                                            window.open(
                                                                `https://a100.gov.bc.ca/pub/hbs/dac/detail/searchSingle.do?searchType=1&txtDCN=${doc["DDN"]}&scaleSiteNumber=&weighSlipNumber=&scalerLicence=&returnNumber=&actionType=Submit&pageName=P048`
                                                            );
                                                        }}
                                                    >
                                                        {doc["DDN"]}
                                                    </Button>
                                                </TableCell>
                                                <TableCell>
                                                    {doc["LDS-number"]}
                                                </TableCell>
                                                <TableCell>
                                                    {doc["load-arrival-number"]}
                                                </TableCell>
                                                <TableCell>
                                                    {doc["scaleSiteName"]}
                                                </TableCell>
                                                <TableCell>
                                                    {doc["scale-date"]}
                                                </TableCell>
                                                <TableCell>
                                                    {doc["scaler-license"]}
                                                </TableCell>
                                                <TableCell>
                                                    {doc["volume"]}
                                                </TableCell>
                                                <TableCell>
                                                    {doc["log-count"]}
                                                </TableCell>
                                                <TableCell>
                                                    {(
                                                        parseFloat(
                                                            doc["volume"]
                                                        ) /
                                                        parseFloat(
                                                            doc["log-count"]
                                                        )
                                                    ).toFixed(2)}
                                                </TableCell>
                                                <TableCell>
                                                    {formatCurrency(
                                                        doc["value"]
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {doc["DV"]}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </ScrollBar>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={loads.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
};

export default PieceScaledLoads;
