import { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { experimentalStyled } from "@material-ui/core";
import MainNavbar from "./MainNavbar";
import MainSidebar from "./MainSidebar";
import Footer from "./home/Footer";
import PageTransition from "./shared/PageTransition";

const MainLayoutRoot = experimentalStyled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
}));

const MainLayoutWrapper = experimentalStyled("div")({
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 56,
});

const MainLayoutContainer = experimentalStyled("div")({
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
});

const MainLayoutContent = experimentalStyled("div")({
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
});

const MainLayout = () => {
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);

    const { pathname } = useLocation();

    useEffect(() => {}, [pathname]);

    return (
        <MainLayoutRoot>
            <MainNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
            <MainSidebar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
            />

            <MainLayoutWrapper>
                <MainLayoutContainer>
                    <MainLayoutContent>
                        <PageTransition>
                            <Outlet />
                            <Footer />
                        </PageTransition>
                    </MainLayoutContent>
                </MainLayoutContainer>
            </MainLayoutWrapper>
        </MainLayoutRoot>
    );
};
export default MainLayout;
