import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Card,
    CardContent,
    Grid,
    Tab,
    Tabs,
    Typography,
    useTheme,
} from "@material-ui/core";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ClientDetails from "src/components/harvest/clientSection/ClientDetails";
import AppraisalDetails from "src/components/harvest/clientSection/AppraisalDetails";
import GradeSummary from "../../components/harvest/GradeSummary";
import SpeciesSummary from "../../components/harvest/SpeciesSummary";
import StratumSummary from "../../components/harvest/StratumSummary";
import DestinationSummary from "src/components/harvest/DestinationSummary";
import InvoiceStatement from "../../components/harvest/InvoiceStatement";
import WasteSummary from "src/components/harvest/WasteSummary";
import WeightScaledLoads from "src/components/harvest/loads/WeightScaledLoads";
import PieceScaledLoads from "src/components/harvest/loads/PieceScaledLoads";
import LoadCalendar from "src/components/harvest/loads/LoadCalendar";
import RateDetails from "src/components/harvest/clientSection/RateDetails";
import LogitHelmet from "src/components/shared/LogitHelmet";
import HarvestMap from "src/components/harvest/HarvestMap";
import slugify from "slugify";
import TotalHarvestSummary from "src/components/harvest/TotalHarvestSummary";

const Harvest = () => {
    const { timberMark } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const invoices = state?.invoices || [];
    const timberMarkStatus = state?.timberMarkStatus || [];
    const [selectedTab, setSelectedTab] = useState(0);
    const scrollPosition = useRef(0);

    const handleChange = (event, newValue) => {
        // Save current scroll position
        scrollPosition.current = window.scrollY;
        setSelectedTab(newValue);
    };

    const noData = !invoices || invoices.length === 0;

    const tabComponents = [
        { label: "Summary", component: TotalHarvestSummary },
        { label: "Load Calendar", component: LoadCalendar },
        { label: "Map", component: HarvestMap },
        { label: "Stratum Summary", component: StratumSummary },
        { label: "Destination Summary", component: DestinationSummary },
        { label: "Grade Summary", component: GradeSummary },
        { label: "Species Summary", component: SpeciesSummary },
        { label: "Invoice Statement", component: InvoiceStatement },
        // { label: "Waste Summary", component: WasteSummary },
        { label: "Weight Scaled Loads", component: WeightScaledLoads },
        { label: "Piece Scaled Loads", component: PieceScaledLoads },
    ];

    const summaryComponents = [
        {
            label: "Rate Details",
            component: RateDetails,
        },
        {
            label: "Client Details",
            component: ClientDetails,
        },
        {
            label: "Appraisal Details",
            component: AppraisalDetails,
        },
    ];

    return (
        <>
            <LogitHelmet pageName={"Harvest"} />
            <Box sx={{ width: "100%" }}>
                <Card>
                    <CardContent>
                        <Typography variant="h3" gutterBottom>
                            {timberMark} Harvest Summary
                        </Typography>
                        {noData && (
                            <Typography variant="h2">
                                No data available
                            </Typography>
                        )}
                    </CardContent>
                </Card>

                {/* Display Client Details and Appraisal Details above the tabs */}
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    {summaryComponents.map((Component, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            {React.createElement(Component.component, {
                                timberMarkStatus,
                                invoices,
                            })}
                        </Grid>
                    ))}
                </Grid>

                {/* Tabs and Content Container */}
                <Card sx={{ marginTop: 3 }}>
                    <Tabs
                        value={selectedTab}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {tabComponents.map((tab, index) => (
                            <Tab key={index} label={tab.label} />
                        ))}
                    </Tabs>

                    {/* Conditional Rendering of Tab Content */}
                    <CardContent>
                        {/* <Grid container xs={12} justifyContent="space-around"> */}
                        <Grid item xs={12}>
                            {tabComponents[selectedTab] &&
                                React.createElement(
                                    tabComponents[selectedTab].component,
                                    {
                                        timberMarkStatus,
                                        invoices,
                                    }
                                )}
                        </Grid>
                        {/* </Grid> */}
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default Harvest;
