import React, { useEffect, useState } from "react";
import InfoCard, { InfoAccordion } from "./InfoCard";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatCurrency, formatNumber } from "src/utils/helpers";
import { M3 } from "src/Constants";
import { calculateTotalStumpage, calculateTotalVolume } from "../helpers";

const RateDetails = ({ timberMarkStatus }) => {
    const [totalEstimatedStumpagePerM3, setTotalEstimatedStumpagePerM3] =
        useState(0);
    const netVolume = calculateTotalVolume(timberMarkStatus);
    const totalStumpage = calculateTotalStumpage(timberMarkStatus);

    useEffect(() => {
        setTotalEstimatedStumpagePerM3(totalStumpage / netVolume);
    }, [totalStumpage, netVolume]);

    return (
        <InfoCard title="Rate Details">
            <Typography variant="body2" color="text.secondary">
                Estimated Pre-Harvest Stumpage: {formatCurrency(totalStumpage)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Estimated Pre-Harvest Volume:{" "}
                {formatNumber(calculateTotalVolume(timberMarkStatus))} {M3}
            </Typography>
            <Typography variant="body2">
                Estimated Pre-Harvest Upset Rate:{" "}
                {formatCurrency(totalEstimatedStumpagePerM3)}/{M3}
            </Typography>
            <InfoAccordion summary="View Details">
                <TableContainer component={Paper}>
                    <Table aria-label="rate details table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Timber Mark</TableCell>
                                <TableCell>Volume ({M3})</TableCell>
                                <TableCell>Stumpage/{M3}</TableCell>
                                <TableCell>Total Stumpage</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {timberMarkStatus.map((status, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {status?.timberMarkInfo?.timberMark ||
                                            "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {formatNumber(
                                            parseFloat(
                                                status?.cruise?.net ||
                                                    status?.cruise?.total ||
                                                    0
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {formatCurrency(
                                            status?.rateInfo?.standrate
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {formatCurrency(
                                            parseFloat(
                                                status?.rateInfo?.standrate || 0
                                            ) *
                                                parseFloat(
                                                    status?.cruise?.net ||
                                                        status?.cruise?.total ||
                                                        0
                                                )
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </InfoAccordion>
        </InfoCard>
    );
};

export default RateDetails;
