import { combineReducers } from "redux";

const defaultState = {
    user_subscribed: false,
    geojson: {},
};

function SubscriptionReducer(state = defaultState, action) {
    switch (action.type) {
        case "Set_Is_User_Subscribed":
            state.user_subscribed = action.payload;
            return state;
        case "Set_User":
            state.user = action.payload;
            return state;
        default:
            return state;
    }
}

function GeojsonReducer(state = defaultState, action) {
    switch (action.type) {
        case "Set_Sale_Geojson":
            state.geojson = action.payload;
            return state;

        default:
            return state;
    }
}

const rootReducer = combineReducers({
    subscription: SubscriptionReducer,
    geojson: GeojsonReducer,
});

export default rootReducer;
