import React, { useState, useEffect } from "react";
import AbstractInvoiceSummary from "./AbstractInvoiceSummary";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@material-ui/core";

const GradeSummary = ({ invoices }) => {
    const [invoicesToPass, setInvoicesToPass] = useState({});
    const [selectedValues, setSelectedValues] = useState(["psi", "wsi"]);
    const [title, setTitle] = useState("");
    const { wsi, psi } = invoices;

    useEffect(() => {
        const newInvoices = {};
        if (selectedValues.includes("psi")) {
            newInvoices.psi = psi;
        }
        if (selectedValues.includes("wsi")) {
            newInvoices.wsi = wsi;
        }

        setInvoicesToPass(newInvoices);

        // Set the title based on selected values
        const titles = [];
        if (selectedValues.includes("psi")) {
            titles.push("Piece Scale");
        }
        if (selectedValues.includes("wsi")) {
            titles.push("Weight Scale");
        }
        setTitle(`Species Summary for ${titles.join(" and ")}`);
    }, [selectedValues, psi, wsi]);

    const handleToggle = (event, newValues) => {
        setSelectedValues(newValues);
    };

    return (
        <div style={{ height: "80%" }}>
            <AbstractInvoiceSummary
                title={title}
                invoices={invoicesToPass}
                parentKey="species"
                childKey="grade-code"
                sortDesc={true}
            />
            <ToggleButtonGroup value={selectedValues} onChange={handleToggle}>
                {psi.length !== 0 ? (
                    <ToggleButton value="psi" color="primary">
                        Piece Scale Invoices
                    </ToggleButton>
                ) : (
                    <Tooltip title="No Piece Scale Invoices">
                        <div>
                            <ToggleButton value="psi" disabled color="primary">
                                Piece Scale Invoices
                            </ToggleButton>
                        </div>
                    </Tooltip>
                )}

                {wsi.length !== 0 ? (
                    <ToggleButton value="wsi" color="primary">
                        Weight Scale Invoices
                    </ToggleButton>
                ) : (
                    <Tooltip title="No Weight Scale Invoices">
                        <div>
                            <ToggleButton value="wsi" disabled color="primary">
                                Weight Scale Invoices
                            </ToggleButton>
                        </div>
                    </Tooltip>
                )}
            </ToggleButtonGroup>
        </div>
    );
};

export default GradeSummary;
