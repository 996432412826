import React from "react";
import InfoCard, { InfoAccordion } from "./InfoCard";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const AppraisalDetails = ({ timberMarkStatus }) => {
    return (
        <InfoCard title="Appraisal Details">
            <InfoAccordion summary="View Details">
                <TableContainer component={Paper}>
                    <Table aria-label="appraisal details table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Timber Mark</TableCell>
                                <TableCell>Start</TableCell>
                                <TableCell>Expires</TableCell>
                                <TableCell>Extensions</TableCell>
                                <TableCell>Method</TableCell>
                                <TableCell>Rate Calculation</TableCell>
                                <TableCell>Conifer Stand Rate</TableCell>
                                <TableCell>Deciduous Stand Rate</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {timberMarkStatus.map((status, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {status?.timberMarkInfo?.timberMark ||
                                            "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {status?.appraisalInfo
                                            ?.effectivedetail || "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {status?.appraisalInfo?.expirydetail ||
                                            "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {status?.tenureInfo?.extensions ||
                                            "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {status?.appraisalInfo?.method || "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {status?.appraisalInfo?.rateCalc ||
                                            "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {status?.appraisalInfo
                                            ?.coniferousStandRateEligibility ||
                                            "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {status?.appraisalInfo
                                            ?.deciduousStandRateEligibility ||
                                            "N/A"}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </InfoAccordion>
        </InfoCard>
    );
};

export default AppraisalDetails;
