import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    Box,
    Tooltip,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import {
    formatCurrency,
    formatNumber,
    formatPercentage,
} from "src/utils/helpers";
import { M3 } from "src/Constants";
import { calculateTotalPerM3Stumpage } from "./helpers";

const StratumSummary = ({ timberMarkStatus, invoices }) => {
    const [stratumData, setStratumData] = useState([]);
    const [uniqueTimberMarks, setUniqueTimberMarks] = useState([]);
    const [selectedTimberMarks, setSelectedTimberMarks] = useState([]);
    const [
        stumpageAverageAcrossTimberMarks,
        setStumpageAverageAcrossTimberMarks,
    ] = useState(0);

    // Organize invoices by stratum and extract unique timber marks
    useEffect(() => {
        if (!invoices || invoices.length === 0) return;

        let timberMarksSet = new Set();
        let stratumMap = {};

        invoices?.wsi?.forEach((invoice) => {
            const timberMark = invoice.header["timber-mark"];
            const stratum = `${invoice.header["population-number"]}-${invoice.header["stratum-number"]}-${invoice.header["sampling-year"]}`;

            timberMarksSet.add(timberMark);

            if (!stratumMap[stratum]) {
                stratumMap[stratum] = {
                    stratum,
                    populationNumber: invoice.header["population-number"],
                    stratumNumber: invoice.header["stratum-number"],
                    samplingYear: invoice.header["sampling-year"],
                    timberMarks: {},
                };
            }

            // Aggregate data by timber mark for each stratum
            if (!stratumMap[stratum].timberMarks[timberMark]) {
                stratumMap[stratum].timberMarks[timberMark] = {
                    totalVolume: 0,
                    totalValue: 0,
                    totalWeight: 0,
                    totalLoads: 0,
                };
            }

            // Update the data for this timber mark
            const volume = parseFloat(invoice.header["total-volume"]);
            const value = parseFloat(invoice.header["invoice-amount"]);
            const weight = parseFloat(invoice.header["total-weight"]);
            const loads = parseFloat(invoice.header["total-loads"]);

            stratumMap[stratum].timberMarks[timberMark].totalVolume += volume;
            stratumMap[stratum].timberMarks[timberMark].totalValue += value;
            stratumMap[stratum].timberMarks[timberMark].totalWeight += weight;
            stratumMap[stratum].timberMarks[timberMark].totalLoads += loads;
        });

        setStratumData(Object.values(stratumMap));
        setUniqueTimberMarks([...timberMarksSet]);
        setSelectedTimberMarks([...timberMarksSet]); // Select all by default
    }, [invoices]);

    useEffect(() => {
        if (timberMarkStatus.length > 1) {
            setStumpageAverageAcrossTimberMarks(
                calculateTotalPerM3Stumpage(timberMarkStatus)
            );
        }
    }, [timberMarkStatus]);

    // Aggregate data across the selected timber marks for each stratum
    const aggregateData = (stratum) => {
        let totalVolume = 0;
        let totalValue = 0;
        let totalWeight = 0;
        let totalLoads = 0;

        selectedTimberMarks.forEach((timberMark) => {
            const timberMarkData = stratum.timberMarks[timberMark];
            if (timberMarkData) {
                totalVolume += timberMarkData.totalVolume;
                totalValue += timberMarkData.totalValue;
                totalWeight += timberMarkData.totalWeight;
                totalLoads += timberMarkData.totalLoads;
            }
        });

        const perMStumpage = totalVolume > 0 ? totalValue / totalVolume : 0;
        const conversion = totalVolume > 0 ? totalWeight / totalVolume : 0;

        return {
            totalVolume,
            totalValue,
            totalWeight,
            totalLoads,
            perMStumpage,
            conversion,
        };
    };

    const handleToggle = (event, newValues) => {
        setSelectedTimberMarks(newValues);
    };

    return (
        <div>
            <Card>
                <CardContent>
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Stratum Summary
                        </Typography>

                        {/* Toggle Button Group for Timbermarks */}
                        <ToggleButtonGroup
                            value={selectedTimberMarks}
                            onChange={handleToggle}
                            aria-label="timbermark toggle"
                            sx={{ marginBottom: 2 }}
                        >
                            {uniqueTimberMarks.map((mark) => (
                                <ToggleButton key={mark} value={mark}>
                                    {mark}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>

                        {/* Table showing each unique stratum */}
                        <TableContainer component={Paper}>
                            <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Stratum</TableCell>
                                        <TableCell>Loads</TableCell>
                                        <TableCell>Volume (m³)</TableCell>
                                        <TableCell>Weight (kg)</TableCell>
                                        <TableCell>
                                            Conversion (kg/m³)
                                        </TableCell>
                                        <TableCell>Value</TableCell>
                                        <TableCell>$/m³</TableCell>
                                        <TableCell>Off-Grade</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stratumData
                                        .filter(
                                            (stratum) =>
                                                aggregateData(stratum)
                                                    .totalVolume > 0
                                        ) // Filter out rows with zero volume
                                        .sort((a, b) => {
                                            const volumeA =
                                                aggregateData(a).totalVolume;
                                            const volumeB =
                                                aggregateData(b).totalVolume;
                                            return volumeB - volumeA; // Sort in descending order
                                        })
                                        .map((stratum) => {
                                            const aggregatedData =
                                                aggregateData(stratum);

                                            return (
                                                <TableRow key={stratum.stratum}>
                                                    <TableCell>
                                                        <Tooltip title="View Stratum on HBS">
                                                            <Button
                                                                variant="outlined"
                                                                fontSize="small"
                                                                onClick={() => {
                                                                    window.open(
                                                                        `https://a100.gov.bc.ca/pub/hbs/smp/viewStratum.do?actionType=View&stratumNumber=${stratum.stratumNumber}&popNumber=${stratum.populationNumber}&year=${stratum.samplingYear}`
                                                                    );
                                                                }}
                                                            >
                                                                {
                                                                    stratum.stratum
                                                                }
                                                            </Button>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatNumber(
                                                            aggregatedData.totalLoads
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatNumber(
                                                            aggregatedData.totalVolume
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatNumber(
                                                            aggregatedData.totalWeight
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatNumber(
                                                            aggregatedData.conversion
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatCurrency(
                                                            aggregatedData.totalValue
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatCurrency(
                                                            aggregatedData.perMStumpage
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatPercentage(
                                                            1 -
                                                                parseFloat(
                                                                    aggregatedData?.perMStumpage
                                                                ) /
                                                                    parseFloat(
                                                                        stumpageAverageAcrossTimberMarks
                                                                    )
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
};

export default StratumSummary;
