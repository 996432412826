import {
    getAuth,
    RecaptchaVerifier,
    signInWithPhoneNumber,
    updateEmail,
    updateProfile,
} from "firebase/auth";
import { getFirestore, getDoc, doc, setDoc } from "firebase/firestore";
import { FREE_TRIAL_DAYS } from "src/Config";
import { USER_COLLECTION } from "src/Constants";
import { useAuth } from "src/contexts/AuthContext";

async function SignOutUserAsync() {
    try {
        getAuth().signOut();
    } catch (error) {}
}
async function CreateUser(userObj) {
    try {
        // Update the Firebase Auth profile
        await updateProfile(getAuth().currentUser, {
            displayName: userObj.name,
        });
        await updateEmail(getAuth().currentUser, userObj.email);

        const db = getFirestore();
        // Reference to the Firestore document
        const docRef = doc(db, USER_COLLECTION, userObj.uid);

        // Add the user document to Firestore
        let userToWrite = {
            id: userObj.uid,
            name: userObj.name,
            email: userObj.email,
            organization: userObj.organization || "",
            phoneNumber: userObj.phoneNumber || "",
            date_joined: new Date(),
            trial_started: new Date(),
        };
        await setDoc(docRef, userToWrite).catch((error) => {
            console.error("Error writing document: ", error);
        });

        return true; // Return a message or the user object if needed
    } catch (err) {
        console.error("Error creating user:", err);
        throw new Error("User creation failed"); // Throw or return an error message
    }
}

async function IsOnFreeTrial(user) {
    const db = getFirestore();
    const userRef = doc(db, USER_COLLECTION, user.uid);
    let trialing = await getDoc(userRef).then((doc) => {
        if (doc.exists) {
            let dateJoined = doc.data().date_joined.toDate();

            const trialPeriodEnds = new Date(dateJoined);
            trialPeriodEnds.setDate(
                trialPeriodEnds.getDate() + FREE_TRIAL_DAYS
            );

            const currentDate = new Date();

            // Compare the timestamps
            const isTrialing =
                trialPeriodEnds.getTime() > currentDate.getTime();
            if (isTrialing) {
                return true;
            } else {
                return false;
            }
        }
    });

    return trialing;
}

export const handlePhoneNumberAuth = async (
    phoneNumber,
    setError,
    setLoading,
    setConfirmationResult
) => {
    const auth = getAuth();
    const db = getFirestore();

    setError(null);
    setLoading(true);

    try {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                getAuth(),
                "recaptcha-container",
                {
                    size: "invisible",
                },
                auth
            );
        }

        const result = await signInWithPhoneNumber(
            auth,
            phoneNumber,
            window.recaptchaVerifier
        );

        setConfirmationResult(result); // Save confirmationResult for later
        setLoading(false);
    } catch (error) {
        setError(error.message);
        setLoading(false);
    }
};

export const handleVerifyCode = async (
    verificationCode,
    confirmationResult,
    setError,
    navigate
) => {
    const auth = getAuth();
    const db = getFirestore();

    if (!confirmationResult) {
        setError("No confirmation result available.");
        return;
    }

    try {
        const result = await confirmationResult.confirm(verificationCode);
        const userDoc = await getDoc(doc(db, USER_COLLECTION, result.user.uid));

        if (userDoc.exists()) {
            navigate("/app", { replace: true }); // Redirect if user exists
        } else {
            navigate("/register", { replace: true }); // Redirect to register if new user
        }
    } catch (error) {
        setError("Incorrect verification code");
    }
};

export { CreateUser, SignOutUserAsync, IsOnFreeTrial };
