import React, { useState, useEffect } from "react";
import AbstractInvoiceSummary from "./AbstractInvoiceSummary";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@material-ui/core";

const GradeSummary = ({ invoices }) => {
    const [invoicesToPass, setInvoicesToPass] = useState({});
    const [selectedValues, setSelectedValues] = useState(["psi", "wsi"]);
    const [title, setTitle] = useState("");
    const { wsi, psi } = invoices;

    useEffect(() => {
        const newInvoices = {};
        if (selectedValues.includes("psi") && psi) {
            newInvoices.psi = psi.filter(
                (invoice) => invoice.header["kind-of-charge"] !== "Waste"
            );
        }

        if (selectedValues.includes("wsi") && wsi) {
            newInvoices.wsi = wsi.filter(
                (invoice) => invoice.header["kind-of-charge"] !== "Waste"
            );
        }

        // filter out the waste invoices

        setInvoicesToPass(newInvoices);

        // Set the title based on selected values
        const titles = [];
        if (selectedValues.includes("psi") && newInvoices.psi) {
            titles.push("Piece");
        }
        if (selectedValues.includes("wsi") && newInvoices.wsi) {
            titles.push("Weight");
        }
        setTitle(`Grade Summary for ${titles.join(" and ")} Scale`);
    }, [selectedValues, psi, wsi]);

    const handleToggle = (event, newValues) => {
        setSelectedValues(newValues);
    };

    return (
        <div>
            <AbstractInvoiceSummary
                title={title}
                invoices={invoicesToPass}
                parentKey="grade-code"
                childKey="species"
            />
            <ToggleButtonGroup value={selectedValues} onChange={handleToggle}>
                {invoicesToPass?.psi?.length !== 0 ? (
                    <ToggleButton value="psi" color="primary">
                        Piece Scale Invoices
                    </ToggleButton>
                ) : (
                    <Tooltip title="No Piece Scale Invoices">
                        <div>
                            <ToggleButton value="psi" disabled color="primary">
                                Piece Scale Invoices
                            </ToggleButton>
                        </div>
                    </Tooltip>
                )}

                {invoicesToPass?.wsi?.length !== 0 ? (
                    <ToggleButton value="wsi" color="primary">
                        Weight Scale Invoices
                    </ToggleButton>
                ) : (
                    <Tooltip title="No Weight Scale Invoices">
                        <div>
                            <ToggleButton value="wsi" disabled color="primary">
                                Weight Scale Invoices
                            </ToggleButton>
                        </div>
                    </Tooltip>
                )}
            </ToggleButtonGroup>
        </div>
    );
};

export default GradeSummary;
