import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import Map from "../shared/Map";
import {
    CreateGeojsonCutblockQueryForTimbermarks,
    GetGeojsonCutblocks,
} from "src/utils/api";

const HarvestMap = ({ timberMarkStatus, invoices }) => {
    const [geoJson, setGeoJson] = useState(null);

    useEffect(() => {
        async function GetAllCutblocks() {
            if (timberMarkStatus && timberMarkStatus.length === 0) return;
            const ids = timberMarkStatus.map(
                (status) => status?.timberMarkInfo?.timberMark
            );

            const query = CreateGeojsonCutblockQueryForTimbermarks(ids);
            const cutblocks = await GetGeojsonCutblocks(query);
            setGeoJson(cutblocks);
        }

        GetAllCutblocks();
    }, [timberMarkStatus]);

    return (
        <Card sx={{ minHeight: "600px", height: "600px", width: "100%" }}>
            <CardContent height="100%">
                {geoJson !== null && (
                    <>
                        <Typography variant="h5" gutterBottom>
                            Harvest Map
                        </Typography>
                        <Map
                            geo={geoJson}
                            geoTextMapLayer={{
                                "text-field": ["concat", ["get", "MAP_LABEL"]],
                                "text-font": ["Open Sans Regular"],
                                "text-size": 16,
                                "text-anchor": "left",
                                "text-offset": [1, 0],
                                "symbol-avoid-edges": true,
                            }}
                        />
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default HarvestMap;
