import { useState, useEffect } from "react";
import { Box, Button, Container, Grid } from "@material-ui/core";
import AccountProfile from "src/components/account/AccountProfile";
import AccountProfileDetails from "src/components/account/AccountProfileDetails";
import { getAuth } from "firebase/auth";
import Loading from "src/components/Loading";

import LogitHelmet from "src/components/shared/LogitHelmet";
import { SignOutUserAsync } from "src/utils/auth";
import InputIcon from "@material-ui/icons/Input";
import { GetUser } from "src/utils/api";

const Account = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        async function GetUserData() {
            var user = await getAuth().currentUser;
            if (user) {
                var userData = await GetUser();
                if (userData) {
                    setCurrentUser(userData);
                    setIsLoading(false);
                }
            }
        }

        GetUserData();
    }, []);

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <LogitHelmet pageName={"Account"} />

                    <Box
                        sx={{
                            backgroundColor: "background.default",
                            minHeight: "100%",
                            py: 3,
                        }}
                    >
                        <Container maxWidth="lg">
                            <Grid container spacing={3}>
                                <Grid item lg={4} md={6} xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="space-between"
                                    >
                                        <AccountProfile user={currentUser} />
                                        <Box pt={2} flexGrow={0.5} />
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="center"
                                        >
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                endIcon={<InputIcon />}
                                                onClick={SignOutUserAsync}
                                            >
                                                Sign Out
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item lg={8} md={6} xs={12}>
                                    <AccountProfileDetails user={currentUser} />
                                </Grid>
                                <Grid item lg={4} md={6} xs={12} />

                                <Grid item lg={4} md={6} xs={12} />
                                {/* <Grid item lg={8} md={6} xs={12}>
                                    <Subscription />
                                </Grid> */}
                            </Grid>
                        </Container>
                    </Box>
                </>
            )}
        </>
    );
};

export default Account;
