import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Card, CardContent, Typography, Box } from "@material-ui/core";
import {
    AggregateSegregationDetailAndExtractParentChildData,
    processSegregationDetailAndExtractParentChildData,
} from "src/utils/hbs";
import { createLabelsAndDataset } from "./helpers";

const AbstractInvoiceSummary = ({
    invoices,
    parentKey,
    childKey,
    title,
    sortDesc,
}) => {
    const [chartData, setChartData] = useState({});

    useEffect(() => {
        if (invoices) {
            let aggregatedData =
                AggregateSegregationDetailAndExtractParentChildData(
                    invoices,
                    parentKey,
                    childKey
                );

            let { labels, datasets } = createLabelsAndDataset(
                aggregatedData,
                parentKey,
                childKey,
                sortDesc
            );

            setChartData({
                labels,
                datasets,
            });
        }
    }, [invoices, parentKey, childKey, sortDesc]);

    const options = {
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                ticks: {
                    beginAtZero: true,
                    callback: (value) => `${value}%`, // Display y-axis in percentage
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    footer: (tooltipItems) => {
                        let total = 0;
                        tooltipItems.forEach((tooltipItem) => {
                            total += tooltipItem.raw;
                        });
                        return `Total: ${total}`;
                    },
                    label: (tooltipItem) => {
                        const value = tooltipItem.raw.value;
                        const percentage = tooltipItem.raw.percentage;
                        return `${value} (${percentage}%)`;
                    },
                },
            },
            // Custom plugin to draw percentage on top of bars
            afterDatasetsDraw: (chart) => {
                const ctx = chart.ctx;
                chart.data.datasets.forEach((dataset, i) => {
                    const meta = chart.getDatasetMeta(i);
                    const total = dataset.data.reduce(
                        (acc, val) => acc + val,
                        0
                    );
                    meta.data.forEach((bar, index) => {
                        const value = dataset.data[index];
                        const percentage =
                            ((value / total) * 100).toFixed(2) + "%";
                        const position = bar.tooltipPosition();
                        ctx.fillStyle = "#000";
                        ctx.textAlign = "center";
                        ctx.textBaseline = "bottom";
                        ctx.fillText(percentage, position.x, position.y - 5);
                    });
                });
            },
        },
        sortDesc: true,
    };

    return (
        <Card>
            <CardContent>
                <Box maxHeight={"100vh"}>
                    <Typography variant="h6" gutterBottom>
                        {title}
                    </Typography>
                    <Bar data={chartData} options={options} />
                </Box>
            </CardContent>
        </Card>
    );
};

export default AbstractInvoiceSummary;
