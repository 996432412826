import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { persistor } from "src/redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import { AuthProvider } from "./contexts/AuthContext";

ReactDOM.render(
    <BrowserRouter>
        <PersistGate loading={null} persistor={persistor}>
            <AuthProvider>
                <App />
            </AuthProvider>
        </PersistGate>
    </BrowserRouter>,
    document.getElementById("root")
);

serviceWorker.unregister();
