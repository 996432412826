import React from "react";

import { Box, Container } from "@material-ui/core";

const Loading = () => {
    return (
        <>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    display: "flex",
                    flexDirection: "column",
                    right: 0,
                    left: 0,
                    marginRight: "auto",
                    marginLeft: "auto",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Container
                    maxWidth="sm"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box sx={{}}>
                        {/* <object
              style={{
                height: "30%",
                width: "30%",
                display: "flex",
                flexDirection: "column",
                marginTop: "50%",
                marginLeft: "35%",
                justifyContent: "center",
                alignItems: "center",
              }}
              type="image/svg+xml"
              data={SVG}
            >
              svg-animation
            </object> */}
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Loading;
