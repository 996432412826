import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Divider,
    Typography,
} from "@material-ui/core";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useAuth } from "src/contexts/AuthContext";
import { USER_COLLECTION } from "src/Constants";

const AccountProfile = (props) => {
    const { user, loading } = useAuth();
    const [userState, setUserState] = useState(null);
    const db = getFirestore();

    useEffect(() => {
        if (user && user.uid) {
            const fetchUserData = async () => {
                const userDoc = await getDoc(
                    doc(db, USER_COLLECTION, user.uid)
                );
                setUserState(userDoc.data());
            };

            fetchUserData();
        }
    }, [user, db]);

    if (!userState) {
        return <div>Loading...</div>; // Replace with your loading indicator
    }

    return (
        <Card {...props}>
            <CardContent>
                <Box
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Avatar
                        src={userState.avatar || ""}
                        sx={{
                            height: 100,
                            width: 100,
                        }}
                    />
                    <Typography color="textPrimary" gutterBottom variant="h3">
                        {userState.name || "User Name"}
                    </Typography>
                    <Typography color="textPrimary" gutterBottom variant="h4">
                        {userState.organization || "Organization"}
                    </Typography>
                </Box>
            </CardContent>
            <Divider />
        </Card>
    );
};

export default AccountProfile;
