import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
    Box,
    Drawer,
    Hidden,
    List,
    Typography,
    useTheme,
} from "@material-ui/core";

import NavItem from "./NavItem";

import Thumbnail from "src/components/Thumbnail";
import mainSidebarItems from "./mainNavbar/mainSidebarItems";
import ContactUsButton from "./shared/ContactUsButton";

const MainSidebar = ({ onMobileClose, openMobile }) => {
    const location = useLocation();
    const theme = useTheme();

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
            }}
        >
            <Box
                p={1}
                sx={{
                    backgroundColor: theme.palette.secondary.main,
                }}
            >
                <Thumbnail />
                <Typography variant="h6" color="white" sx={{ marginLeft: 2 }}>
                    FibreFlow
                </Typography>

                <Typography
                    color={theme.palette.secondary.contrastText}
                    variant="h4"
                >
                    Logit Now
                </Typography>
            </Box>
            <Box sx={{ p: 1 }}>
                <List>
                    {mainSidebarItems.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                        />
                    ))}
                </List>
            </Box>
            <Box p={1} sx={{ flexGrow: 0.2 }}>
                <ContactUsButton color="primary" />
            </Box>
            <Box sx={{ flexGrow: 0.9 }} />
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                    PaperProps={{
                        sx: {
                            width: 192,
                            // color: "primary",
                        },
                    }}
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

MainSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

MainSidebar.defaultProps = {
    onMobileClose: () => {},
    openMobile: false,
};

export default MainSidebar;
